import BookDemoButtonComponent, { IProps } from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModal/BookDemoButton';
import BookDemoButtonLegacyComponent from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';

const BookDemoButton = ({ legacy = false, ...props }: Omit<IProps, 'tenant'> & {
  legacy?: boolean,
}) => {
  const Component = legacy ? BookDemoButtonLegacyComponent : BookDemoButtonComponent;

  return (
    <Component
      tenant={ useDataroomTenantContext().tenant }
      { ...props }
    />
  );
};

export default BookDemoButton;
