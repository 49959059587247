import React, { useMemo } from 'react';
import cn from 'classnames';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { useScheduleDemoContext } from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoContext';
import CopyToClipboardButton from '@/Framework/UI/Atoms/Button/CopyToClipboardButton';
import styles from './scheduleDemoFormSuccess.scss';
import config from '@/Framework/config';
import { MONTH_DAY_YEAR_FORMAT, TIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';

interface IProps {
  tenant: string,
}

const ScheduleDemoFormSuccess = ({ tenant }: IProps) => {
  const {
    submittedFormValues: {
      firstName,
      lastName,
      corporateEmail,
      companyName,
      phone,
      demoDateTime,
      timeZone,
    },
  } = useScheduleDemoContext();

  const tenantConfig = useMemo(() => {
    return Object.values(config.tenant)
      .find((item) => item.code === tenant) || config.tenant.finsight;
  }, [tenant]);

  const getTextToCopy = () => {
    const dateLine = `Date: ${ formatInTimeZone(new Date(Number(demoDateTime) * 1000), timeZone.timeZone, MONTH_DAY_YEAR_FORMAT) }`;
    const timeLine = `Time: ${ formatInTimeZone(new Date(Number(demoDateTime) * 1000), timeZone.timeZone, TIME_FORMAT) }`;
    const nameLine = `Name: ${ firstName } ${ lastName }`;
    const companyLine = `Company: ${ companyName }`;
    const emailLine = `Email: ${ corporateEmail }`;
    const phoneLine = `Phone: ${ phone }`;

    return [
      dateLine,
      timeLine,
      nameLine,
      companyLine,
      emailLine,
      phoneLine,
    ].join('\n');
  };

  return (
    <div className={ styles.successContainer } data-test="successContainer">
      <div className={ styles.title }>
        <div className={ styles.mainTitle }>Thank you! Your Demo request was successfully added.</div>
        <div className={ styles.subTitle }>
          Our team will send a meeting planner within twenty minutes as confirmation
        </div>
        <div>Your appointment is scheduled for:</div>
      </div>
      <div className={ styles.appointment }>
        <div className={ styles.appointmentContainer }>
          <div>
            <div className={ styles.row }>
              <div className={ styles.label }>Date:</div>
              <div className={ cn(styles.value, styles.bold) } data-test="date">
                { formatInTimeZone(new Date(Number(demoDateTime) * 1000), timeZone.timeZone, MONTH_DAY_YEAR_FORMAT) }
              </div>
            </div>
            <div className={ styles.row }>
              <div className={ styles.label }>Time:</div>
              <div className={ cn(styles.value, styles.bold) } data-test="time">
                { formatInTimeZone(new Date(Number(demoDateTime) * 1000), timeZone.timeZone, TIME_FORMAT) }
              </div>
            </div>
            <div className={ styles.row }>
              <div className={ styles.label }>Name:</div>
              <div className={ styles.value } data-test="fullName">{ firstName } { lastName }</div>
            </div>
            <div className={ styles.row }>
              <div className={ styles.label }>Company:</div>
              <div className={ styles.value } data-test="company">{ companyName }</div>
            </div>
            <div className={ styles.row }>
              <div className={ styles.label }>Email:</div>
              <div className={ styles.value } data-test="email">{ corporateEmail }</div>
            </div>
            <div className={ styles.row }>
              <div className={ styles.label }>Phone:</div>
              <div className={ styles.value } data-test="phone">{ phone }</div>
            </div>
          </div>
        </div>
      </div>
      <div className={ styles.actionsWrp }>
        <CopyToClipboardButton
          labelAfterCopy="Copied to Clipboard"
          labelBeforeCopy="Copy to Clipboard"
          content={ getTextToCopy() }
          dataTest="actionCopyButton"
          copiedIcon={ IconCheck }
          copiedElemClassName={ styles.copiedText }
        />
      </div>
      <div className={ styles.contactUs }>
        If you have any questions, please reach out to{ ' ' }
        <a href={ `mailto:${ tenantConfig.supportEmail }?subject=${ tenantConfig.name } Pricing Request: Custom %26 Enterprise` }>
          { tenantConfig.supportEmail }
        </a>{ ' ' }
        or call 1-888-997-6610
      </div>
    </div>
  );
};

export default ScheduleDemoFormSuccess;
