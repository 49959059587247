import React from 'react';
import cn from 'classnames';
import ScheduleDemoContext, { useScheduleDemoContext } from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoContext';
import ScheduleDemoForm from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoForm';
import ScheduleDemoFormSuccess from './ScheduleDemoFormSuccess';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import { Logo, LogoProduct } from '@dealroadshow/uikit/core/components/Logo';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

import waves from './assets/waves.svg';
import styles from './scheduleDemoModal.scss';

interface IProps {
  tenant: string,
  closeModal?: () => void,
  afterClose?: () => void,
}

const ScheduleDemoModal = ({
  tenant,
  closeModal,
  afterClose,
}: IProps) => {
  const { isSubmitting, isSubmitted } = useScheduleDemoContext();
  const modalHeaderClasses = cn(styles.modalHeader, `tenant-header-${ tenant }`);

  const title = (
    <div className={ modalHeaderClasses }>
      <img
        className={ styles.backgroundImage }
        src={ waves }
        alt=""
      />
      <Logo
        product={ tenant as LogoProduct }
        colorType={ Logo.COLOR_TYPE.WHITE }
        type={ Logo.TYPE.STANDART }
        className={ styles.logoImage }
      />
      <div className={ styles.modalHeaderTitle }>{ isSubmitted ? 'Demo Scheduled' : 'Schedule a Demo' }</div>
    </div>
  );

  return (
    <Modal
      hideOnOverlayClicked
      className={ styles.scheduleDemoModal }
      isVisible
      onCloseClicked={ closeModal }
      afterClose={ afterClose }
      dataTest={ `${ tenant }ScheduleDemoModal` }
      title={ title }
    >
      { isSubmitted && !isSubmitting
        ? <ScheduleDemoFormSuccess tenant={ tenant } />
        : (
          <>
            <div>
              <p className={ styles.contentDescription }>
                It takes 15-30 minutes and we operate on your availability.<br />
                Our team will send a meeting planner within twenty minutes as confirmation.
              </p>
            </div>
            <ScheduleDemoForm
              submitButtonClassName={ styles.submitButton }
              isSubmitting={ isSubmitting }
              tenant={ tenant }
              withLabels
              buttonTitle={ !isSubmitting ? <>Submit Request</> : (
                <span>
                  <Spinner className={ styles.spinner } />
                </span>
              ) }
            />
          </>
        ) }
    </Modal>
  );
};

export default (props: IProps) => (
  <ScheduleDemoContext>
    <ScheduleDemoModal { ...props } />
  </ScheduleDemoContext>
);
