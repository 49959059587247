import React from 'react';
import BookDemoButtonComponent from '../../BookDemoButton';
import { Tenant } from '@/Framework/Tenant/vo/types/Tenant';
import useScheduleDemoModal from '../useScheduleDemoModal';
import { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

export interface IProps {
  tenant: Tenant,
  buttonComponent?: React.ElementType,
  title?: string,
  className?: string,
  variantType?: keyof typeof ButtonVariantType,
}

const BookDemoButton = ({ tenant, buttonComponent, title, className, variantType }: IProps) => {
  const {
    isScheduleDemoModalVisible,
    showScheduleDemoModal,
    hideScheduleDemoModal,
    ScheduleDemoModal,
  } = useScheduleDemoModal();

  return (
    <BookDemoButtonComponent
      isScheduleDemoModalVisible={ isScheduleDemoModalVisible }
      showScheduleDemoModal={ showScheduleDemoModal }
      hideScheduleDemoModal={ hideScheduleDemoModal }
      scheduleDemoModal={ ScheduleDemoModal }
      tenant={ tenant }
      buttonComponent={ buttonComponent }
      title={ title }
      className={ className }
      variantType={ variantType as keyof typeof ButtonVariantType }
    />
  );
};

export default BookDemoButton;
